import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/admin/order/page',
    method: 'get',
    params
  })
}
// 分配客户经理
export function assignUserAPI(data) {
  return http({
    url: '/admin/order/assignUser',
    method: 'post',
    data
  })
}
// 客户经理列表
export function getAdminUserList(params) {
  return http({
    url: `/admin/user/list`,
    method: 'get',
    params
  })
}
// 发货时间
export function handleDelieryAPI(data) {
  return http({
    url: '/admin/order/delivery',
    method: 'post',
    data
  })
}
