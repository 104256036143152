<template>
  <div class="wrapper">
    <div class="header-container">
      <div class="left">
        <el-form inline class="search-header" :model="searchData" label-width="100px">
          <el-form-item label="发货时间">
            <el-date-picker
              style="width:300px"
              v-model="searchData.deliveryTimeSlot"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              size="small"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="未付天数">
            <el-input-number
                    size="small"
                    style="width:138px"
                    v-model="searchData.leftOverdueDays"
                    :step="1"
                    :min="0"
                    step-strictly
                    controls-position="right"
            ></el-input-number>
            <span> 至 </span>
            <el-input-number
                    size="small"
                    style="width:138px"
                    v-model="searchData.rightOverdueDays"
                    :step="1"
                    :min="0"
                    step-strictly
                    controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="客户姓名" v-show="isShow">
            <el-input v-model="searchData.memberName" style="width:300px" placeholder="请输入客户姓名" size="small"></el-input>
          </el-form-item>
          <el-form-item label="手机号" v-show="isShow">
            <el-input v-model="searchData.memberMobile" style="width:300px" placeholder="请输入手机号" size="small"></el-input>
          </el-form-item>
          <el-form-item label="订单号" v-show="isShow">
            <el-input v-model="searchData.orderSn" style="width:300px" placeholder="请输入订单号" size="small"></el-input>
          </el-form-item>
          <el-form-item label="唯一码" v-show="isShow">
            <el-input v-model="searchData.sysOrderFixSn" style="width:300px" placeholder="请输入唯一码" size="small"></el-input>
          </el-form-item>
          <el-form-item label="审核人" v-show="isShow">
            <el-input v-model="searchData.reviewer" style="width:300px" placeholder="请输入审核人" size="small"></el-input>
          </el-form-item>
          <el-form-item label="平台" v-show="isShow">
            <el-select style="width:300px" v-model="searchData.sysCode" clearable placeholder="请选择" size="small">
              <el-option v-for="item in sysList" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商户" v-show="isShow">
            <el-select style="width:300px" v-model="searchData.merchantCode" clearable placeholder="请选择" size="small">
              <el-option v-for="item in merchantList" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户经理" v-show="isShow">
            <el-select size="small" style="width:300px" v-model="searchData.userName" filterable clearable placeholder="请选择">
              <el-option v-for="item in userList" :key="item.id" :label="item.userName" :value="item.userName"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="" v-show="isShow"> </el-form-item> -->
          <!-- <el-form-item label="" v-show="isShow"> </el-form-item> -->

          <!-- <el-form-item class="arrow" label=" ">
        <el-button type="primary" icon="el-icon-search" size="small" @click="getList">查询</el-button>
        <el-button @click="reset" size="small" icon="el-icon-refresh">重置</el-button>
        <span class="open" @click="isShow = !isShow">{{ isShow ? '收起' : '展开' }}</span>
        <i class="updown" :class="['el-icon-arrow-up', isShow ? '' : 'go']"></i>
      </el-form-item> -->
        </el-form>
      </div>
      <div class="right">
        <span class="open" @click="isShow = !isShow">{{ isShow ? '收起' : '展开' }}</span>
        <i class="updown" :class="['el-icon-arrow-up', isShow ? '' : 'go']"></i>
        <el-button style="margin-left:10px" type="primary" icon="el-icon-search" size="small" @click="getList">查询</el-button>
        <el-button @click="reset" size="small" icon="el-icon-refresh">重置</el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <!-- <div class="btn-group">
      <div></div>
      <div>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="$router.push({ name: 'OrderAddEdit' })">添加</el-button>
      </div>
    </div> -->

    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="page_size" />
    <div style="height:50px"></div>

    <el-dialog title="分配客户经理" :visible.sync="dialogVisible" width="700px" :show-close="false">
      <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
        <el-form-item prop="userId" label="客户经理">
          <el-select style="width:300px" clearable filterable v-model="formData.userId" placeholder="请选择">
            <el-option v-for="item in userList" :key="item.id" :label="item.userName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" " style="margin: 20px 0">
          <el-button type="" @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveUser">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="发货时间" :visible.sync="dialogVisibleDate" width="700px" :show-close="false">
      <el-form ref="form" :model="formUpdateData" label-width="100px">
        <el-form-item label="发货时间" prop="deliveryTime" :rules="[{ required: true, message: '请选择发货时间', trigger: 'blur' }]">
          <el-date-picker
            style="width:300px"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="formUpdateData.deliveryTime"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" " style="margin: 20px 0">
          <el-button type="" @click="dialogVisibleDate = false">取消</el-button>
          <el-button type="primary" @click="saveDate">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getDictEnumListAPI } from '@/enum/dict.js'
import { getListAPI, assignUserAPI, getAdminUserList,handleDelieryAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

const columns = [
  {
    label: '平台',
    prop: 'sysName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['sysName']}</p>
    }
  },
  {
    label: '商户',
    prop: 'merchantName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['merchantName']}</p>
    }
  },
  {
    label: '客户姓名',
    prop: 'member',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['member']['memberName']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'memberMobile',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['member']['mobile']}</p>
    }
  },
  {
    label: '客户经理',
    prop: 'userName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['userName']}</p>
    }
  },
  {
    label: '审核人',
    prop: 'reviewer',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['reviewer']}</p>
    }
  },
  {
    label: '订单状态（系统）',
    prop: 'sysStatus',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['sysStatus']}</p>
    }
  },
  {
    label: '未付天数',
    prop: 'overdueDays',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['overdueDays'] <= -1 ? '无' : (row['overdueDays'] == 0 ? '未逾期' : row['overdueDays'] + '天')}</p>
    }
  },
  {
    label: '在逾金额',
    prop: 'overdueAmount',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['overdueAmount']}</p>
    }
  },
  {
    label: '订单号',
    prop: 'orderSn',
    minWidth: '190',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['orderSn']}>{row['orderSn']}</p>
      )
    }
  },
  {
    label: '唯一码',
    prop: 'sysOrderFixSn',
    minWidth: '190',
    customRender(h, row) {
      return (
              <p class="one-line" title={row['sysOrderFixSn']}>{row['sysOrderFixSn']}</p>
    )
    }
  },
  {
    label: '交易单号',
    prop: 'tradeSn',
    minWidth: '275',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['tradeSn']}>
          {row['tradeSn']}
        </p>
      )
    }
  },
  {
    label: '下单时间',
    prop: 'orderTime',
    minWidth: '170',
    customRender(h, row) {
      return <p>{row['orderTime']}</p>
    }
  },
  {
    label: '发货时间',
    prop: 'deliveryTime',
    minWidth: '170',
    customRender(h, row) {
      return <p>{row['deliveryTime']}</p>
    }
  },
  {
    label: '支付单号',
    prop: 'paySn',
    minWidth: '190',
    customRender(h, row) {
      return <p>{row['paySn']}</p>
    }
  },
  {
    label: '支付时间',
    prop: 'payTime',
    minWidth: '170',
    customRender(h, row) {
      return <p>{row['payTime']}</p>
    }
  },
  {
    label: '商品信息',
    prop: 'orderGoods',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['orderGoods']['goodsDesc']}</p>
    }
  },
  {
    label: '单期租金',
    prop: 'unitRentAmount',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['unitRentAmount']}</p>
    }
  },
  {
    label: '租期',
    prop: 'rentPeriod',
    minWidth: '80',
    customRender(h, row) {
      return <p>{row['rentPeriod']}</p>
    }
  },
  {
    label: '总租金',
    prop: 'totalRentAmount',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['totalRentAmount']}</p>
    }
  },
  {
    label: '买断金',
    prop: 'buyoutAmount',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['buyoutAmount']}</p>
    }
  },
  {
    label: '溢价金',
    prop: 'premiumAmount',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['premiumAmount']}</p>
    }
  },
  {
    label: '意外保',
    prop: 'insuranceAmount',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['insuranceAmount']}</p>
    }
  },
  {
    label: '开始时间',
    prop: 'startTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['startTime']}</p>
    }
  },
  {
    label: '结束时间',
    prop: 'endTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['endTime']}</p>
    }
  },
  {
    label: '操作',
    minWidth: '180',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            v-show={this.isShowBtn(this.AUTH_BTN.order_assign_manager)}
            underline={false}
            type="primary"
            onClick={() => this.addUser(row)}
          >
            分配客户经理
          </el-link>
          <el-link
            v-show={this.isShowBtn(this.AUTH_BTN.order_delivery)}
            style="margin:0 10px"
            underline={false}
            type="primary"
            onClick={() => this.updateDate(row)}
          >
            发货时间
          </el-link>
          <el-link
            v-show={this.isShowBtn(this.AUTH_BTN.bill_list)}
            underline={false}
            type="primary"
            target="_blank"
            onClick={() => {
              let link = this.$router.resolve({ name: 'BillList', query: { orderId: row['id'] } })
              window.open(link.href, '_blank')
            }}
          >
            账单明细
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',

  mixins: [authBtnMixin],

  data() {
    return {
      dialogVisibleDate: false,
      dialogVisible: false,
      isShow: false, //控制箭头
      columns,
      currentPage: 1,
      page_size: 50,
      total: 0,
      list: [],
      searchData: {
        leftStartDate: '',
        rightStartDate: '',
        leftEndDate: '',
        rightEndDate: '',
        deliveryTimeSlot: [],
        memberName: '',
        memberMobile: '',
        orderSn: '',
        sysOrderFixSn: '',
        reviewer: '',
        sysCode: '',
        merchantCode: '',
        userName: '',
        leftOverdueDays: undefined,
        rightOverdueDays: undefined,
      },
      formData: {
        userId: ''
      },
      rules: {
        userId:[
          { required: true, message: "请选择客户经理", trigger: "blur" }
        ]
      },
      merchantList: [], //商户列表
      sysList: [], //平台列表
      userList: [], //客户经理列表
      pickerOptions: {
        shortcuts: [
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              picker.$emit('pick', [start, start])
            }
          },
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              picker.$emit('pick', [end, end])
            }
          },
          {
            text: '近7天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      formUpdateData: {
        deliveryTime: '',
        id: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
    this.getDictEnumList()
    this.getAdminUserList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },
  methods: {
    async getList() {
      const { deliveryTimeSlot, memberName, memberMobile, orderSn, sysOrderFixSn, reviewer, sysCode, merchantCode, userName, leftOverdueDays, rightOverdueDays } = this.searchData
      let params = {
        memberId: this.$route.query.memberId || undefined,
        leftDeliveryDate: deliveryTimeSlot?.[0] || undefined,
        rightDeliveryDate: deliveryTimeSlot?.[1] || undefined,
        memberName,
        memberMobile,
        orderSn,
        sysOrderFixSn,
        reviewer,
        sysCode,
        merchantCode,
        userName,
        leftOverdueDays,
        rightOverdueDays,
        page: this.currentPage,
        pageSize: this.page_size
      }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    addUser({ id, userId }) {
      this.dialogVisible = true
      this.$set(this.formData, 'userId', userId)
      this.$set(this.formData, 'id', id)
    },
    saveUser(id) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          assignUserAPI(this.formData).then(() => {
            this.$message.success('分配成功')
            this.getList()
            this.dialogVisible = false
          })
        } else {
          return false
        }
      })
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    async getDictEnumList() {
      this.merchantList = await getDictEnumListAPI('identity:merchant')
      this.sysList = await getDictEnumListAPI('identity:sys')
    },
    async getAdminUserList() {
      const res = await getAdminUserList({})
      this.userList = res || []
    },
    // 发货时间
    updateDate({ id, deliveryTime }) {
      this.dialogVisibleDate = true
      this.$set(this.formUpdateData, 'id', id)
      this.$set(this.formUpdateData, 'deliveryTime', deliveryTime)
    },
    saveDate() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          handleDelieryAPI(this.formUpdateData).then(() => {
            this.dialogVisibleDate = false
            this.$message.success('操作成功')
            this.getList()
          })
        } else {
          return false
        }
      })
    }
  },
  activated() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 800px;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left {
      flex: 1;
      // padding-top: 4px;
    }
    .right {
      margin-left: 20px;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
  }
  .search-header /deep/ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .el-range-separator {
      padding: 0;
    }
    .arrow {
      // text-align: right;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
    .el-form-item {
      width: 30%;
      min-width: 400px;
    }
  }
  .el-divider {
    margin: 10px 0;
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
