import { http } from '@/http/axios.js'
export const CURRENT_STATUS = [
  {
    name: '逾期',
    value: 2
  },
  {
    name: '正常',
    value: 1
  }
]
export const BILL_STATUS = [
  {
    name: '已付款',
    value: 1
  },
  {
    name: '未付款',
    value: 0
  }
]
export const MENU_TYPE = [
  { name: '目录', value: 1 },
  { name: '菜单', value: 2 },
  { name: '按钮', value: 3 }
]

// 同步常量
export const SYNC_ZUJI = 'ok_zuji'
export function getDictEnumListAPI(dictCode) {
  return http({
    url: '/admin/dictEnum/list',
    method: 'get',
    params: { dictCode }
  }).then(res => {
    return res.map(item => {
      return {
        name: item.enumText,
        value: item.enumCode
      }
    })
  })
}
export const token =
  'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyTmFtZSI6Iui2hee6p-euoeeQhuWRmCIsImV4cCI6MTY3ODY5ODg4NiwidXNlcklkIjoxLCJpYXQiOjE2Nzc4MzQ4ODZ9.XUFKn5BRIACIA-PToXxHVgEfALxd3sddtLBnFKy84AvY9vyILjHU6xykH0uJ6lajYoUs9T0D4sRbPKqIkzTZ6g'
